/*
  Application Login Page
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Antd
import { Form, Button } from "antd";

// Redux
import { useDispatch ,useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

// Helper
import {
  errorNotification,
} from "../../../helpers/Notification";

// Form Elements
import { Email, } from "../../Common/FormElements";

// Actions

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import { API_URLS } from "../../../constants/ApiUrl";
import { REDUX_STATES } from "../../../constants/ReduxStates";
import STATUS_CODES from "../../../constants/StatusCodes";

//ACTIONS
import { addRequest } from "../../../store/actions/CRUDAction";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

// Style
import '../style.scss';

//redux states
const { FORGOT_PASSWORD , LOADING } = REDUX_STATES

function ResetPassword(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();
  
  //redux states
  const{
    [FORGOT_PASSWORD + LOADING] : loading=false
  } = useSelector((state)=>state.Crud)

  //onsubmit
  const onSubmit = (data) => {
    dispatch(addRequest(API_URLS.RESET_PASSWORD, data, {}, FORGOT_PASSWORD)).then(
      (response) => {
        form.resetFields();
        history.push(APP_URL.AUTH.EMAIL_VERIFICATION)
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.Failed
          : e?.response?.status === STATUS_CODES.NOT_FOUND
          ? LOCALIZATION.LOCALIZATION.UNABLE_TO_RESET_PASSWORD
          : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  };

  return (
    <AuthenticationBase loading={loading} message={LOCALIZATION.RESET_YOUR_PASSWORD} >
      <p className="subheading-text" > {LOCALIZATION.SEND_RESET_EMAIL_INFO} </p>
      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <div className="form-group">
          <Email
            placeholder={LOCALIZATION.EMAIL + " *"}
            className="form-control"
            name="email"
            required={true}
          />
        </div>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            className="mt-3"
          >
            {LOCALIZATION.FORGOT_PASSWORD}
          </Button>
        </Form.Item>
      </Form>
      <div className="text-center mt-4 forget-password-link" >
        { LOCALIZATION.ALREADY_HAVE_PASSWORD }
        <a className=""
          onClick={() => history.push(APP_URL.AUTH.LOGIN)}
        >
          {LOCALIZATION.SIGN_IN}
        </a>
      </div>
    </AuthenticationBase>
  );
}

export default ResetPassword;