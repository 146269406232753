/*
  Application Login Page Base Layout
*/

import React, { useEffect } from 'react';

// Redux
import {
  useDispatch,
} from 'react-redux';

//Components
import Loading from '../Loading';
import Error from '../Common/Alerts/Error';

// Antd
import { Row, Col } from 'antd';

// Actions
import { logout } from '../../store/actions/AuthAction';

// Logo
import logo from '../../assets/images/hiremii-logo.png';

// Localization
import LOCALIZATION from "../../services/LocalizationService";

// Style
import './style.scss';

function AuthenticationBase({
  loading,
  message,
  children,
  error
}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout())
  }, [dispatch]);

  return (
    <div className="login-wrapper">
      {
        loading &&
        // true &&
        <Loading />
      }
      
      <Row>  
        <Col lg={24} md={24} xs={24}>
          <div className="form-row">       
            <Row className="login-form-wrapper pb-3">
              {/*Authentication Forms with Heading*/}              
              <Col md={24} xs={24}>

                {/*Error Message*/}
                <Error
                  show={!!error}
                  message={error}
                />

                <div className="app-logo">
                  <img src={ logo } alt="hiremii" />
                  <h1>{ message || LOCALIZATION.CONNECTING_THE_FUTURE_FOR_YOU }</h1>
                </div>

                {children}

                <div className="needHelpTxt mt-8">
                  <a href="https://hiremii.com/faq">{ LOCALIZATION.FAQ }</a>
                  <i>|</i>
                  <a href="https://hiremii.com/privacy">{ LOCALIZATION.PRIVACY_POLICY }</a>
                  <i>|</i>
                  <a href="https://hiremii.com/terms">{ LOCALIZATION.TERMS_CONDITIONS }</a>
                </div>

              </Col>
            </Row>
          </div>
        </Col>

      </Row>
    </div>
  );
}

export default AuthenticationBase;