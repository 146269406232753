export const API_URLS = {
  LOGIN: "/accounts/login",
  RESET_PASSWORD:"/accounts/reset_password/",
  SIGN_UP:"/accounts/sign-up",
  EMAIL_VERIFICATION:"/accounts/email_verification/",
  CHANGE_PASSWORD: "/change-password/",
  REFRESH_TOKEN: "/refresh-token/",
  PASSWORD_RESET_CONFIRM:"/accounts/password_reset_confirm/",
  CREATE_SHORTLIST:"/shortlist/",
  GET_INDUSTRIES:"/shortlist/get-industries/",
  GET_CVS:"/shortlist/get-cvs/:id/",
  DELETE_CVS:"/shortlist/del-cv/:id",
  SEND_EMAIL:"/shortlist/send-email/",
  GET_CANDIDATES:"/shortlist/get-candidates/",
  UPDATE_CANDIDATE:"/shortlist/update-candidate/",
  ADWRITER:"/adwriter/list-job-ads/",
  GET_THRESHOLD:"/shortlist/get-threshold-mapping"
}
