export const REDUX_STATES = {
  DEFAULT_KEY: "listing" + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: "dropdown" + new Date().getTime(),
  DEFAULT_ADD_KEY: "add" + new Date().getTime(),
  DEFAULT_VIEW_KEY: "view" + new Date().getTime(),
  DEFAULT_EDIT_KEY: "edit" + new Date().getTime(),
  DEFAULT_DEL_KEY: "delete" + new Date().getTime(),
  DEFAULT_FILTERS_KEY: "filter" + new Date().getTime(),
  DEFAULT_SELECTED_KEY: "selected" + new Date().getTime(),
  LOADING: "_loading",
  ERROR: "_error",
  ADD: "_add",
  VIEW: "_view",
  EDIT: "_edit",
  FILTERS: "_filters",
  SELECTED: "_selected",
  FORGOT_PASSWORD:"forgotpassword",
  SIGNUP:"signup",
  CONFIRMATION:"confirmation",
  CONFIRM_PASSWORD:"confirmpassword",
  SHORTLIST:"shortlist",
  SHORTLIST_RESULT:"shortlistResult",
  UPDATE_CANDIDATE:"updateCandidate",
  SHORTLIST_LISTING:"shortlist_listing",
  ADWRITER_LISTING:"adwriter_listing",
  INDUSTRIES:"industries",
  SEND_EMAIL:"send_email",
  SHORTLIST_CVS:"shortlist_cvs",
  THRESHOLD:"threshold"
};
