/*
  Application Login Page
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Antd
import { Form, Button, Checkbox, Input } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

// Helper
import {
  errorNotification,
} from "../../../helpers/Notification";

// Form Elements
import { Text ,Email} from "../../Common/FormElements";

// Actions
import { addRequest } from "../../../store/actions/CRUDAction";

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import STATUS_CODES from "../../../constants/StatusCodes";
import { PASSWORD_VALIDATOR } from "../../../constants/Patterns";
import { REDUX_STATES } from "../../../constants/ReduxStates";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

// Logo
import { API_URLS } from "../../../constants/ApiUrl";

//redux states
const { SIGNUP , LOADING } = REDUX_STATES

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();

  // Redux States
  const{
    [SIGNUP + LOADING] : loading=false
  } = useSelector((state)=>state.Crud)

  // On Submit
  const onSubmit = (data) => {
    if(data.privacyPolicy) {
      dispatch(addRequest(API_URLS.SIGN_UP, data, {}, SIGNUP)).then(
          () => {
            history.push(APP_URL.AUTH.EMAIL_VERIFICATION);
          },
          (e) => {
            const message = e?.response?.data?.email[0]
              ? e?.response?.data?.email[0]
              : e?.response?.status === STATUS_CODES.NOT_FOUND
              ? LOCALIZATION.UNABLE_CREATE_ACCOUNT
              : LOCALIZATION.NETWORK_ERROR;

            errorNotification(message);
          }
        )
      } else {
        errorNotification(LOCALIZATION.ACCEPY_PRIVACY_POLICY);
      }
  };

  //Inputs placeholder 
  const getPlaceholder = (label, opt = false) => {
    const text = !!opt ? " (Optional)" : " *";
    return label + text;
  };

  return (
    <AuthenticationBase
      loading={loading}
      message={LOCALIZATION.CREATE_YOUR_ACCOUNT}
    >
      <div className="px-4">
        <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
          <div className="form-group">
            <Text
              className="form-control"
              name="first_name"
              required={true}
              placeholder={getPlaceholder(LOCALIZATION.FIRST_NAME)}
            />
          </div>

          <div className="form-group">
            <Text
              className="form-control"
              name="last_name"
              required={true}
              placeholder={getPlaceholder(LOCALIZATION.LAST_NAME)}
            />
          </div>

          <div className="form-group">
            <Email
              className="form-control"
              name="email"
              required={true}
              placeholder={getPlaceholder(LOCALIZATION.EMAIL)}
            />
          </div>

          <div className="form-group">
            <Text
              className="form-control"
              name="company"
              placeholder={getPlaceholder(LOCALIZATION.COMPANY, true)}
            />
          </div>

          <Form.Item
            className="form-group"
            name="password"
            rules={[
              {
                required: true,
                message: "",
              },
              {
                pattern: PASSWORD_VALIDATOR,
                message: LOCALIZATION.PASSWORD_VALIDATOR_MESSAGE,
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder={getPlaceholder(LOCALIZATION.PASSWORD)}
              className="form-control"
            />
          </Form.Item>

          <Form.Item
            className="form-group"
            name="repassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Password not match"));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={getPlaceholder(LOCALIZATION.RE_ENTER_PASSWORD)}
              className="form-control"
            />
          </Form.Item>

          <div className="form-group help-text mt-3">
            <Form.Item name="privacyPolicy" valuePropName="checked">
              <Checkbox>
                {" "}
                By ticking this box you agree to our <a target="_blank" rel="noreferrer" href="https://hiremii.com/privacy"> <u>
                  Privacy Policy
                </u> </a> & <a target="_blank" rel="noreferrer" href="https://hiremii.com/terms"> <u>Terms and Conditions</u></a>{" "}
              </Checkbox>
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              block
              size="medium"
              type="primary"
              htmlType="submit"
              className="mt-10"
            >
              {LOCALIZATION.CREATE_YOUR_ACCOUNT}
            </Button>
          </Form.Item>
        </Form>
        <div className="forget-password-link text-center mt-4">
          {LOCALIZATION.ALREADY_HAVE_ACCOUNT}
          <a onClick={() => history.push(APP_URL.AUTH.LOGIN)}>
            {LOCALIZATION.SIGN_IN}
          </a>
        </div>
      </div>
    </AuthenticationBase>
  );
}

export default Login;
