import react from "react";

//antd
import { Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

function Layout({label,ToolTipText,iconSize,iconColor , text}) {
  return (
    <p className="input-tool-tip">
      {label} 
      <span className="info">
        <Tooltip
          title={ToolTipText}
          placement="topLeft"
          color={"#6065F1"}
          mouseEnterDelay={0.5}
        >
          <InfoCircleFilled  />
        </Tooltip>
      </span>
      <span className="info-text" >
       {text} 
      </span>
    </p>
  );
}

export default Layout
