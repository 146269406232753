/*
  Application Login Page
*/

import React from "react";
import { useHistory } from "react-router-dom";

// Antd
import { Form, Button } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

// Helper
import {
  successNotification,
  errorNotification,
} from "../../../helpers/Notification";

// Form Elements
import { Text, Password } from "../../Common/FormElements";

// Actions
import { login } from "../../../store/actions/AuthAction";

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import STATUS_CODES from "../../../constants/StatusCodes";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);

  // On Submit
  const onSubmit = (data) => {
    dispatch(login(data)).then(
      () => {
        successNotification(LOCALIZATION.LOGIN_SUCCESSFULLY);
        history.push(APP_URL.WELCOME);
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.detail
          : e?.response?.status === STATUS_CODES.NOT_FOUND
          ? LOCALIZATION.INVALID_EMAIL_OR_PASSWORD
          : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  };

  return (
    <AuthenticationBase 
      loading={loading}
      message={ LOCALIZATION.WELCOME_BACK }
    >
      <div className="px-6">
        <Form 
          form={form} 
          ref={formRef} 
          onFinish={onSubmit} 
          layout="vertical"
        >
          <div className="form-group">
            <Text
              className="form-control"
              name="email"
              required={true}
              placeholder={LOCALIZATION.EMAIL}
            />
          </div>

          <div className="form-group">
            <Password
              className="form-control"
              name="password"
              required={true}
              minLength={1}
              placeholder={LOCALIZATION.PASSWORD}
            />
          </div>

          <Form.Item>
            <Button
              block
              size="medium"
              type="primary"
              htmlType="submit"
              className="mt-4"
            >
              {LOCALIZATION.SIGN_IN}
            </Button>
          </Form.Item>
        </Form>
        <div className="help-text mt-4 mb-6">
          <a
            onClick={() => history.push(APP_URL.AUTH.FORGOT_PASSWORD)}
          >
            {LOCALIZATION.FORGOT_PASSWORD}
          </a>
        </div>
        <div className="text-center mt-4 forget-password-link">
          { LOCALIZATION.DONT_HAVE_ACCOUNT }
          <a className=""
            onClick={() => history.push(APP_URL.AUTH.SIGN_UP)}
          >
            {LOCALIZATION.SIGN_UP}
          </a>
        </div>
      </div>
    </AuthenticationBase>
  );
}

export default Login;
