//import { SECTIONS } from './MenuSections';

//const { CLIENT, PROJECT, EMPLOYEE, ALLOCATION, PERSONAL_SKILLSET, EMPLOYEE_SKILLSET , INVOICING } = SECTIONS;

const URL = {
  WELCOME: "/welcome", // Welcome,

  SHORTLIST: "/shortlist", // Shortlist,
  ADD_SHORTLIST: "/shortlist/add", // add shortlist,
  EMAIL_PREVIEW: "/shortlist/email-preview/:id", // email preview
  EDIT_SHORTLIST: "/shortlist/add/:id", // edit shortlist,
  RESULT_SHORTLIST: "/shortlist/result/:id", // edit shortlist,
  AD_WRITER: "/adwriter", // Adwriter,

  AUTH: {
    LOGIN: '/login', // Login
    RESET_PASSWORD:'/reset-password',// reset Password
    FORGOT_PASSWORD:'/forgot-password',// forgot Password
    EMAIL_VERIFICATION:'/email-verification',// email verification
    CONFIRMATION:'/verification/:uid/:token',// confirmation
    CONFIRMED_PASSWORD:'/ConfirmPassword/:uid/:token',//confirmed password
    SIGN_UP:'/sign-up',// Sign Up
  },
  ERROR: {
    PAGE404: '/page404',
    PAGE500: '/page500',
  }
};

export default URL;