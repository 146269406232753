/*
  Actual application starts from this file
*/

import React from 'react';
import { 
  BrowserRouter, 
  Switch, 
  Route 
} from 'react-router-dom'; //https://css-tricks.com/the-hooks-of-react-router/
import Loadable from 'react-loadable';

// Antd
import { notification } from 'antd';

// Constants
import APP_URL from './constants/ApplicationUrls';
import { NOTIFICATION_DURATION } from './constants/General';

// Components
import PrivateRoute from './components/PrivateRoute';
import Loading from './components/Loading';
// import Page500 from './components/ErrorPages/Page500';

// Auth Components
import Login from './components/Authentication/Login';
import ForgotPassword from './components/Authentication/ForgotPassword';
import ResetPassword from './components/Authentication/ResetPassword';
import EmailVerification from './components/Authentication/EmailVerification';
import Confirmation from './components/Authentication/Confirmation';
import SignUp from './components/Authentication/SignUp';
import ConfirmPassword from './components/Authentication/ConfirmPassword';


// Styling
import './assets/sass/style.scss';

// Localization
import LOCALIZATION from './services/LocalizationService';

// Package.json
const packageJson = require('../package.json');

// Notification Configuration for whole application
notification.config({
  placement: 'topRight',
  duration: NOTIFICATION_DURATION,
  rtl: false
});

// Base Layout
const BaseLayout = Loadable({
  loader: () => import('./components/BaseLayout'),
  loading: Loading
});

function App() {

  return (
    <div className={`App`}>
      <BrowserRouter basename={ packageJson?.homepage || '' }>
        <Switch>
          {/* 
            Open Routes Here 
          */}

          {/* Login */}
          <Route
            path={ APP_URL.AUTH.LOGIN }
            name={ LOCALIZATION.LOGIN }
            component={ Login }
          />

          {/* Forgot Password */}
          <Route
            path={ APP_URL.AUTH.FORGOT_PASSWORD }
            name={ LOCALIZATION.FORGOT_PASSWORD }
            component={ ForgotPassword }
          />

          {/* Reset Password */}
          <Route
            path={ APP_URL.AUTH.RESET_PASSWORD }
            name={ LOCALIZATION.RESET_PASSWORD }
            component={ ResetPassword }
          />

          {/* Reset Password */}
          <Route
            path={ APP_URL.AUTH.EMAIL_VERIFICATION }
            component={ EmailVerification }
          />

          {/* Sign Up */}
          <Route
            path={ APP_URL.AUTH.SIGN_UP }
            name={ LOCALIZATION.SIGN_UP }
            component={ SignUp }
          />

          {/* Confirmation */}
          <Route
            path={ APP_URL.AUTH.CONFIRMATION }
            component={ Confirmation }
          />

          {/* Confirmed Password */}
          <Route
            path={ APP_URL.AUTH.CONFIRMED_PASSWORD }
            component={ ConfirmPassword }
          />

          {/* Restricted Routes Here */}
          <PrivateRoute 
            component={ BaseLayout } 
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;