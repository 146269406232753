/*
  Application Login Page
*/

import React from "react";
import { useHistory, useParams } from "react-router-dom";

// Antd
import { Form, Button, Input } from "antd";

// Redux
import { useDispatch,useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

//ACTIONS
import { updatePatchRequest } from "../../../store/actions/CRUDAction";

// Helper
import {
  successNotification,
  errorNotification
} from "../../../helpers/Notification";

// Constants
import { API_URLS } from "../../../constants/ApiUrl";
import APP_URL from "../../../constants/ApplicationUrls";
import {PASSWORD_VALIDATOR} from "../../../constants/Patterns";
import {REDUX_STATES} from "../../../constants/ReduxStates"

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

//redux states
const { CONFIRM_PASSWORD , LOADING } = REDUX_STATES

function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  let formRef = React.createRef();

  //getting query parameter
  const { uid, token } = useParams();

   //redux states
   const{
    [CONFIRM_PASSWORD + LOADING] : loading=false
  } = useSelector((state)=>state.Crud)

  //onsubmit
  const onSubmit = (data) => {
    data["uid"] = uid;
    data["token"] = token;
    dispatch(
      updatePatchRequest(API_URLS.PASSWORD_RESET_CONFIRM, data, {}, CONFIRM_PASSWORD)
    ).then(
      () => {
        form.resetFields();
        successNotification(LOCALIZATION.PASSWORD_RESET_SUCCESSFULLY);
        history.push(APP_URL.AUTH.LOGIN);
      },
      (e) => {
        errorNotification(e?.response?.data?.confirm_password || LOCALIZATION.UNABLE_TO_RESET_PASSWORD);
      }
    );
  };

  return (
    <AuthenticationBase loading={loading}>
      <div className="app-logo">
        <h1>New Password</h1>
      </div>

      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <Form.Item
          className="form-group"
          name="password"
          label={LOCALIZATION.NEW_PASSWORD}
          rules={[
            {
              required: true,
              message: "",
            },
            {
              pattern:PASSWORD_VALIDATOR,
              message:LOCALIZATION.PASSWORD_VALIDATOR_MESSAGE
            }
          ]}
          hasFeedback
        >
          <Input.Password className="form-control"/>
        </Form.Item>

        <Form.Item
          className="form-group"
          name="confirm_password"
          label={LOCALIZATION.NEW_PASSWORD_CONFIRM}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Password not match"));
              },
            }),
          ]}
        >
          <Input.Password className="form-control"/>
        </Form.Item>
        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            className="mt-3"
          >
            {LOCALIZATION.CONFIRM_PASSWORD}
          </Button>
        </Form.Item>
      </Form>
    </AuthenticationBase>
  );
}

export default Login;
