/*
  Application Email Verification
*/

import React, { useEffect } from "react";
import { useHistory ,useParams } from "react-router-dom";

// Components
import AuthenticationBase from "..";

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import {API_URLS} from "../../../constants/ApiUrl"
import STATUS_CODES from "../../../constants/StatusCodes"
import {REDUX_STATES} from "../../../constants/ReduxStates"

//Helper
import {
   successNotification,
   errorNotification
} from "../../../helpers/Notification";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

//Store
import {updatePatchRequest} from "../../../store/actions/CRUDAction"

//Redux
import  { useDispatch,useSelector } from "react-redux"

//redux states
const { CONFIRMATION , LOADING } = REDUX_STATES

function Confirmation() {
  const history = useHistory();
  const dispatch = useDispatch()

  //getting query parameter
  const data = useParams()

  //redux states
  const{
    [CONFIRMATION + LOADING] : loading=false
  } = useSelector((state)=>state.Crud)

  //run first
  useEffect(()=>{
    
    dispatch(updatePatchRequest(API_URLS.EMAIL_VERIFICATION , data, {} ,CONFIRMATION )).then(
      ()=>{
        successNotification(LOCALIZATION.EMAIL_VERIFIED_SUCCESSFULLY)
        history.push(APP_URL.AUTH.LOGIN)
      },
      (e)=>{
        const message = e?.response?.data?.Failed
          ? e?.response?.data?.Failed
          : e?.response?.status === STATUS_CODES.NOT_FOUND
          ? LOCALIZATION.UNABLE_VERIFICATION
          : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }

    )
  },[])

  return (
    <AuthenticationBase 
      loading={loading}
    >
      <div className="text-center process-text">
        <h4 className="message-text">Please Wait!</h4>
        <i className="icon-security confirm-ico"></i>
        <h4 className="message-text">Verification is in process.</h4>
      </div>
      
      <div className="text-center mt-4">
        { LOCALIZATION.ALREADY_HAVE_ACCOUNT }
        <a className="forget-password-link"
          onClick={() => history.push(APP_URL.AUTH.LOGIN)}
        >
          {LOCALIZATION.SIGN_IN}
        </a>
      </div>
    </AuthenticationBase>
  );
}

export default Confirmation;