/*
  Input Text
*/

import React from "react";

// Antd
import { Upload, Form } from "antd";

// Localization
import LOCALIZATION from "../../../../services/LocalizationService";

import { SPACE_VALIDATIOR } from "../../../../constants/Patterns";

// Style
// import "./style.scss";

function Dragger({
  name,
  label,
  placeholder,
  accept,
  maxCount,
  onChange,
  disabled,
  required,
  validator,
  validateTrigger,
  notwrapInForm,
  textLine1,
  textLine2,
  action,
  fileList,
  beforeUpload,
  icon,
  multiple,
  onRemove
}) {
  const { Dragger } = Upload;
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // Rules
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
  ];

  // Custom Validations
  !!validator && rules.push(validator);

  // Input Text
  const DraggerUpload = (
    <Dragger
      placeholder={placeholder || label}
      onChange={onChange || null}
      accept={accept}
      maxCount={maxCount}
      disabled={disabled}
      action={action}
      customRequest={dummyRequest}
      fileList={fileList}
      beforeUpload={beforeUpload}
      multiple={multiple}
      onRemove={onRemove}
    >
      {icon}
      <p className="ant-upload-hint">{textLine1}</p>
      <p className="ant-upload-hint">{textLine2}</p>
    </Dragger>
  );

  return !!notwrapInForm ? (
    <>{DraggerUpload}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
    >
      {DraggerUpload}
    </Form.Item>
  );
}

export default Dragger;
