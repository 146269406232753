/*
  Application Email Verification
*/

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import AuthenticationBase from "..";

// Constants
import APP_URL from "../../../constants/ApplicationUrls";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";

function EmailVerification() {
  const history = useHistory();

  const [loading, setloading] = useState(false);

  return (
    <AuthenticationBase loading={loading} message="Help is on the way!">
      <div className="text-center process-text">
        <h4 className="message-text">
          {LOCALIZATION.PLEASE_CHECK_YOUR_EMAIL}
        </h4>
        <i className="icon-directions_run help-ico"></i>
      </div>

    </AuthenticationBase>
  );
}

export default EmailVerification;
